import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import AuditService from '@/services/audit.service.js'
import ContactService from '@/services/contacts.service.js'
import { ChannelType } from '@/models/ChannelType'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import getEnv from '@/util/env'
import DateRange from '@/components/DateRange/DateRange.vue'
import EventBus from '@/util/EventBus'
import moment from 'moment'
import { Colors } from '@/classes/colors/Colors'
import { TrackingEvents } from '@/classes/TrackingEvents'

export default {
  name: 'AuditStats',
  components: {
    HeaderTopDashboard,
    DateRange,
  },
  data () {
    return {
      loading: false,
      contact: null,
      searchInput: null,
      globalControl: new GlobalControl(),
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      content: [],
      searchTerm: '',
      pageCount: 0,
      total: 0,
      startDate: null,
      endDate: null,
      channel: -1,
      eventId: -1,
      statusesColors: Colors.statusesColors,
      trackingEvents: TrackingEvents.events,
      debouncedInput: null,
      maxDate: null,
    }
  },
  computed: {
    infoText () {
      return this.$t('La búsqueda se realizará automáticamente al introducir un contacto en el buscador') + '.'
    },
    headers () {
      return [
        { text: this.$t('Fecha'), value: 'created_at' },
        { text: this.$t('Campaña'), value: 'campaign_name' },
        { text: this.$t('Estado'), value: 'statusGuid', align: 'center' },
        { text: this.$t('Canal'), value: 'channel_id', align: 'center' },
        { text: this.$t('Evento'), value: 'event_id', align: 'center' },
        { text: this.$t('Estadísticas'), value: 'sent_id' },
      ]
    },
    chanels () {
      return [
        { id: -1, name: this.$t('Todos los canales') },
        { id: ChannelType.SMS_ID, name: 'SMS' },
        { id: ChannelType.MAILING_ID, name: 'Mailing' },
        { id: ChannelType.VOICE_INTERACTIVE_ID, name: this.$t('Llamada de voz') },
      ]
    },
    events () {
      return [
        { id: -1, name: this.$t('Todos los eventos'), color: null },
        ...this.trackingEvents.map(e => ({ ...e, name: this.$t(e.name) })),
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: [
          this.searchTerm,
          this.globalControl.initDate,
          this.globalControl.endDate,
          this.channel,
          this.eventId,
        ],
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.contact = val
        }, 700)
      },
    },
  },
  watch: {
    contact: {
      handler () {
        if (this.contact) {
          this.options.page = 1
          this.setParamsAndGetData()
        } else {
          this.content = []
        }
      },
    },
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
    searchInput (search) {
      search && search !== this.contact.selected.search && this.searchByPhoneOrEmail(search)
    },
  },
  methods: {
    onResetDates () {
      this.maxDate = null
      console.log('maxDate: ', this.maxDate)
    },
    setInitDate (initDate) {
      this.maxDate = moment(initDate).add(3, 'month').format('YYYY-MM-DD')
      console.log('maxDate: ', this.maxDate)
    },
    allowedDates (date) {
      return date > moment(this.globalControl.endDate).subtract(3, 'month').format('YYYY-MM-DD') &&
            date < moment().add(1, 'day').format('YYYY-MM-DD')
    },
    getEvent (item) {
      return this.events.find(e => e.id === item.event_id) || { name: '', color: '' }
    },
    searchByPhoneOrEmail (search) {
      this.loading = true
      const params = {
        search: search,
      }
      ContactService.searchByPhoneEmailId(params)
        .then(
          (response) => {
            this.contact.contacts = response
          },
          (error) => {
            ContactService.errorResponse(
              error.response.status,
              this.$t('Error al intentar ver perfil del usuario'),
              this.$t(error.response.data.message),
            )
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    exportCsv () {
      const url = getEnv('backend_endpoint') + 'api/audit/csv' + '?' + this.getUrlParams() + '&token=' + JSON.parse(localStorage.getItem('user')).token
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.getParams()).toString()
    },

    goToStats (item) {
      const campaignId = item.campaign_id
      switch (parseInt(item.channel_id)) {
        case ChannelType.SMS_ID:
          this.$router.push('/stats/sms/campaign/' + campaignId)
          break
        case ChannelType.MAILING_ID:
          this.$router.push('/stats/mailing/campaign/' + campaignId)
          break
        case 7:
          this.$router.push('/stats/voice/campaign/' + campaignId)
          break
      }
    },
    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      AuditService.getDataTable(params)
        .then(
          (response) => {
            this.content = JSON.parse(JSON.stringify(response.data))
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.total = response.total
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    setParamsAndGetData () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        recipient: this.contact,
        startDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
        channel: this.channel !== -1 ? this.channel : '',
        eventId: this.eventId !== -1 ? this.eventId : '',
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
        ? 'asc'
        : 'desc'
      }
      if (this.contact) {
        this.getDataFromApi(params)
      }
    },
    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        recipient: this.contact || '',
        startDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
        channel: this.channel !== -1 ? this.channel : '',
        eventId: this.eventId !== -1 ? this.eventId : '',
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      return params
    },
    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
    },
  },
  created () {

  },
}
